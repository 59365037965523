<!-- HTML: This is Where You Put the HTML Code -->
<template>
	<div class="flood-table" ref="panelContent">
		<search
			v-if="!isMobile"
			color='black'
			size='medium'
			logo='hightide'
			class='search'
			placeholder='Search for another address... '
		/>

		<Transition name="slide-fadeY">
		<div class="section" v-show='$route.params.type == "property" && input.location.coordinates != undefined  && !isMobile'>
			<div class='streetView' id="street-view">
				<streetView
					:coordinates='coordinates'
				/>
				<!--
				<span v-else>
					<span v-for="info in title">
						<h1> {{info}} </h1>
					</span>
					<h2> Number of Properties: {{ input.attributes.numberOfProperties }}  </h2>
				</span>
				-->
			</div>
		</div>
		</Transition>

		<div class='section' id='info'>
			<div id="location-info">
			<div class='address'>
				<template v-if='$route.params.type == "city"'>
				<h5><strong> {{ input.location.address.city }}, {{ input.location.address.state }} </strong></h5>	
				</template>
				<template v-else-if='$route.params.type == "property"'>
					<h5><strong>  {{ input.location.address.street }}, {{ input.location.address.city }}, {{ input.location.address.state }} </strong></h5>	
				</template>
				<span v-else>
					<span v-for="info in title">
						<h1> {{info}} </h1>
					</span>
					<h2> Number of Properties: {{ input.attributes.numberOfProperties }}  </h2>
				</span>
			</div>
			
			
			<span>
			<div class='attributes' v-if='$route.params.type == "property" && input.attributes.floodZone && attributes'>
				<flood-info
						:floodRisk='floodRisk(controls,textContent)'
						:floodZone='floodZone'
						:groundElevation='attributes.groundElevation.mean'
						:floodElevation='attributes.staticBaseFloodElevation'
						:floorArea='input.attributes.area.toLocaleString()'
						:firstFloorHeight=FFE
						variant = 'hightide'
					/>
					<!--
				<div v-if='$route.params.type == "property" && input.attributes.floodZone'>
					<div class='attribute'> FEMA Flood Zone:  <div> {{ input.attributes.floodZone }} </div>
					</div>
				</div>
				<div v-if='$route.params.type == "property" && input.attributes.floodZone'>
					<div class='attribute'> Base Flood Elevation:  
						<div>{{ staticBaseFloodElevation }}</div>
					</div>
				</div>
				<div v-if='$route.params.type == "property"'>
					<div class='attribute'> Area:
						<div>{{ input.attributes.area.toLocaleString() }} sqft</div>
					</div>
				</div>
				<div v-if='$route.params.type == "property"'>
					<div class='attribute'> First Floor Height: <div>{{FFE}} ft</div>
					</div>
				</div>	
				-->
			</div>
			</span>
			</div>
		</div>
		
		<div class='section chart-container damageByStormCategory'>
			<damageByStormCategory
				v-if='EL != undefined'
				class='chart safariBullshitWrapper'
				:expectedLoss='EL'
				:startingExpectedLoss='EL'
				:projection="dataSource"
				:max='maxEL'
				buildingCategory='total'
				:year='year'
				width='97%'
				height='fit-content'
			/>
			<spinner v-else	/>
		</div>

		<div class='section chart-container lossOverTime'>
			<lossOverTime
				v-if='AAL'	
				class='chart safariBullshitWrapper'
				:AAL='AAL'
				:maxAAL='maxAAL'
				:projection="dataSource"
				:year='year'
				yAxesLabel='Label Y'
				xAxesLabel='Label X'
				height='100%'
				width='100%'
			/>	
			<spinner v-else	/>
		</div>
		<!--
		<card class='chart-container histogram'>
			<histogram
				v-if='histogramData != null'
				class='chart safariBullshitWrapper'
				:buckets='[0, 10000, 50000, 100000, 500000, 100000000]'
				:data='histogramValues'
				:expectedLoss="highlightedValue"	
				:percentile="percentile"	
				:town='input.location.address.city'
				width='100%'
				height='100%'
			/>
		</card>
		
		
		<card class='chart-container bug-report v-center'>
			<bug-report />
		</card>
		-->
	
		<div class="section chart-container print-report v-center"
			v-if="input.location.address.county == 'Pinellas' && $route.params.type == 'property'"

		>
			<h3> Click below to download the Pinellas County real estate disclosure for this property. </h3>
			<div style="width:100%;display: flex; justify-content: center;">
			<o-button variant="primary" v-on:click="printReport" style="margin-top:10px;padding:5px;background-color: #4E84C4;border-radius: 5px; height:34px;">
				Print Report
			  </o-button>
			</div>
		</div>

		<sidepanel-footer v-if="textContent['brand'] && textContent['footer']" variant="hightide"/>
		
	</div>
</template>

<script>
import Card from '@/components/Card'
import Search from '@/components/Search'
import LossOverTime from '@/components/govSub/LossOverTime'
import Spinner from '@/components/Spinner'
import DamageByStormCategory from '@/components/govSub/DamageByStormCategory'
import Histogram from '@/components/govSub/Histogram'
import StreetView from '@/components/StreetView'
import BugReport from '@/components/BugReport'
import axios from 'axios'
import FloodInfo from '@/components/arkly/FloodInfo'
import debounce from 'lodash/debounce'
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import { saveAs } from 'file-saver'
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import { getAuthToken } from '@/helpers/auth.js'
import Footer from '@/components/sidepanel/Footer'

export default {
	name: 'InfoTable',
	props: [
		'input',
		'year',
		'stormCategory',
		'dataSource',
		'useType',
		'firstFloorElevation',
	],
	components: {
		Card,
		Search,
		LossOverTime,
		DamageByStormCategory,
		'flood-info': FloodInfo,
		'sidepanel-footer': Footer,
		Histogram,
		StreetView,
		BugReport,
		Spinner,
	},
	data() {
		return {
			expectedLoss: {},
			startingExpectedLoss: this.input.expectedLoss,
			startingAAL: null,
			histogramData: null,
			percentile: null,
			houseLiftingCost: null,
			averageAnnualExpectedLoss: null,
			isLoading: false,
			percentileInstance: null, 
			axiosController: null,
			axiosInstance: null,
		}
	},
	computed: {
		/*
		floodRisk() {
			if (this.AAL) {
				var currentFloodRisk = this.AAL[this.controls.dataSource][this.controls.year]['mean']
				if (currentFloodRisk<1000) {
					return this.textContent['property_view']['low-flood-risk-desc']['label'] +  " $1,000 "
				} else{
					return '$' + this.abbreviateNumbers(this.AAL[this.controls.dataSource][this.controls.year]['mean'], 2, false)
				}		
			} else {
				return ''
			}
		},
		*/
		title() {
			if (this.$route.params.type == "blockgroup") {
				return [
					'Blockgroup: ' + this.$route.params.id,
					'County: '+ this.input.location.address.county 
				]
			} else {
				return [this.input.location.address.county + ' County']
			}
		},
		economicLoss(){
			if (this.$route.params.type == "city") {
				return this.input.economicLoss
			} else {
				return this.input.expectedLoss
			}
		},
		coordinates(){
			if (this.input.location != null){
				return {'lat': this.input.location.coordinates.lat, 'lng': this.input.location.coordinates.lng}
			}
		},
		histogramValues(){
			return this.histogramData['total']['NOAA'][this.stormCategory][this.year]['mean'].map(x => x['count'])
			
		},
		highlightedValue(){
			if (this.$route.params.type == "city") {
				return -1 
			} else {
				return this.economicLoss['total']['NOAA'][this.stormCategory][this.year]['mean']
			}
		},
		staticBaseFloodElevation() {
			const elevation = this.input.attributes.staticBaseFloodElevation
			if (elevation == '-9999') {
				return 'N/A'
			} else {
				return elevation + ' ft'
			}
		},
		...mapState(useConfigStore, ['textContent','url', 'user', 'isMobile','controls']),
		...mapState(useSurfStore, ['abbreviateNumbers','attributes','floodZone','AAL', 'EL', 'maxEL', 'maxAAL', 'FFE']),
	},
	methods: {
		firstFloorElevationChanged(event) {
			parseFloat(event.target.value)
		},	
		printReport: async function() {
			const url = '/assets/pinellas-report.pdf'
			const existingPdfBytes = await fetch(url).then((res) =>
				res.arrayBuffer()
			)

			const pdfDoc = await PDFDocument.load(existingPdfBytes)
			const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

			const pages = pdfDoc.getPages()
			const firstPage = pages[0]
			const { width, height } = firstPage.getSize()

			firstPage.drawText(this.input.attributes.ids.parcel , {
				x: 370,
				y: 313,
				size: 10,
				font: helveticaFont,
				color: rgb(1, 0.1, 0.1),
			})

			if (['A','AE','AO','V','VE'].includes(this.input.attributes.floodZone)){
				firstPage.drawText('X', {
					x: 287.9, 
					y: 280,
					size:14,
					font: helveticaFont,
					color: rgb(0, 0, 0),
				})
			} else {
				//box for moderate to low flood risk zones
				firstPage.drawText('X', {
					x: 287.9, 
					y: 227.7,
					size:14,
					font: helveticaFont,
					color: rgb(0, 0, 0),
				})
			}

			const pdfBytes = await pdfDoc.save('hightide-report.pdf')
			const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true })
			saveAs(pdfDataUri, this.$route.params.id + '.pdf')
		},
		...mapActions(useConfigStore, ['addComponentRef']),	
		...mapActions(useSurfStore, ['floodRisk']),
	},
	mounted() {
		this.axiosInstance = axios.create()
		this.axiosController = new AbortController()
		this.addComponentRef('panel-content', this.$refs.panelContent)
	},
	watch: {
		AAL: {
			handler: function(newValue){
				//console.log(newValue)
			},
			deep: true,
		},
		EL:{
			handler: function(newValue){
				//console.log(newValue)
			},
			deep: true,
		},
		user:{
			handler: function(newUser){
				//print(newUser)
			},
			deep: true,
		},
		maxAAL(newValue) {
			
		},
		isMobile(newState) {
			console.log(newState)
		}
	},
}

</script>

<!-- CSS -->
<style lang="scss" scoped>
@import "../../style/style.scss";

$offwhite: #F2F4F5;

.flood-table {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	background-color: transparent; //$sidepanel-color;
	//border-top-right-radius: $border-radius;
	border-bottom-right-radius: 0;
	//height: 100%;
	max-width: 100%;
	width: 400px;
	//padding: 0 20px;
	overflow-y: auto;
	overflow-x: clip;
	overscroll-behavior-y: none;
}
	/*
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto minmax(380px, auto) minmax(380px, auto) auto auto;
	grid-gap: 10px;	
	//overflow: hidden;
	padding: 0px;
	//background-color: #394149;
	background-color: transparent;
	min-height: 100%;
	*/

.search {
	width: 100%;
}
.section {
	max-width: 100%;
	max-height: 100%;
	background-color: white;
	border-radius: 5px;
	min-width: 376px;
}

#info {
	padding: 20px;
}

#location-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	//gap: $padding;
	text-align: left;
	grid-area: location;
}

.address {
	padding: 10px 5px 0 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;
	text-align: left;
}
.address h5 strong {
	font-size: 24px !important;
	font-weight: bold;
}

h1 {
	font-weight: normal;
}
h2 {
	font-weight: normal !important;
}

#street-view {
	height: 100%;
	//max-height: 300px;
	width: 100%;
	border: 0px solid black;
	border-radius: $border-radius;

}

.location * {
	width: 100%;
}

.attributes {
	//display: grid;
	//grid-template-columns: 1fr 1fr;
	//grid-template-rows: 1fr 1fr;
	width: 100%;
	height: 100%;
	padding: 8px;
}

.attribute {
	display: inline;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding: 4px;
	font-family: $header-family;
	//font-weight: 700; 
	font-size: 1rem;
}

.attribute * {
	display: inline;
	font-weight: normal;
}

.attributes h2, .attributes h3 {
	margin: 0px;
}

.info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.info *:nth-child(n+2) {
	margin-top: 0px;
}

.control {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: auto;
}

// nth-child(n+2) allows you to select every item that isn't 
// the first child. Unlike :not(:first-child) it is accepted in 
// IE
.control *:nth-child(n+2) {
	margin-left: 10px;
}

.control div {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: auto;
}

.control-label {
	padding-bottom: 4px;
}

.chart-container {
	display: inline-block;
	//max-height: 450px !important;
	max-width: 100%;
	min-width: 376px;
	min-height: 370px;
	overflow: hidden;
	padding: 20px;
	box-sizing:border-box;  /** add this **/
	-moz-box-sizing:border-box; /** add this **/
	-webkit-box-sizing:border-box; /** add this **/
	-ms-box-sizing:border-box; /** add this **/
}

.chart {
	width: 100%;
	height: 100%;
}

.house-lifting-content {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 30px;
}

.print-report {
	//padding: 50px;
	min-height: 0px !important;
}

@media only screen and (max-width: 800px) {
	.flood-table {
		padding: 0px;
		width: 100%;
		background-color: $offwhite;
	}

	#location-info {
		align-items: stretch;
	}

	.section {
		width: 100%;
		padding: 20px;
	}

	#info {
		padding: 20px;
		padding-top: 0px;
	}

	.damageByStormCategory {
		background-color: #F2F4F5;
		color: #F2F4F5;
	}
}

.slide-fadeY-enter-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  
}

.slide-fadeY-leave-active {
	transition: all 0.3s ease-out;
}

.slide-fadeY-enter-from,
.slide-fadeY-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

</style>
